@use "../../styles/variables";

.navigation-pane {
  padding: 1.5rem;
  border: 1px solid variables.$input-border;

  & > span {
    display: block;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &--active {
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: black;
      font-size: 0.875rem;
    }

    .disabled {
      pointer-events: none;
    }

    &__icon {
      width: 2rem;
      height: 2rem;
      border: 2px solid variables.$input-border;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 50%;
      }

      &--active {
        border-color: variables.$hauc-green;
      }

      &--complete {
        border-color: variables.$hauc-green;
        background-color: variables.$hauc-green;
      }
    }
  }
}
