h1 {
  margin: 0;
  font-size: 2rem;
  line-height: 1;
}

h2 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1;
}
