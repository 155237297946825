@use "../../../styles/variables";

.checkbox {
  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid variables.$input-border;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    cursor: pointer;

    img {
      height: 18px;
      opacity: 0;
      pointer-events: none;
      user-select: none;
    }

    &--checked {
      background-color: variables.$hauc-green;

      img {
        opacity: 1;
      }
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
