@use "../../styles/variables";

.table-container {
  overflow: auto;
  width: 100%;

  &__title {
    color: variables.$dark-grey;
    font-size: 1.3125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 32px;
}

th,
td {
  padding: 12px;
  text-align: left;
  font-size: 1.125rem;
  font-weight: 400;
}

th {
  background-color: variables.$dark-grey;
  color: white;
}

tr:nth-child(even) {
  background-color: variables.$light-grey;
}
