.body-text-container {
  max-width: 800px;
}

.footer {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  gap: 32px;
  flex-direction: row;
}
