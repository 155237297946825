@use "../../styles/variables";

.doughtnut-chart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;

  &__doughnut {
    height: 18.75rem;
    width: 18.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__centre-text {
      color: variables.$dark-grey;
      position: absolute;
      text-align: center;
      display: flex;
      max-width: 10rem;
      max-height: 4.5rem;
      flex-direction: column;

      &__value {
        font-size: 2.25rem;
        font-weight: 500;
        margin: 0;
      }

      &__units {
        font-size: 1.125rem;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

.legend {
  color: variables.$dark-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;

  &__row {
    width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    min-width: 16rem;

    &__key {
      &--grey {
        min-width: 0.875rem;
        height: 0.875rem;
        background-color: variables.$dark-grey;
      }

      &--light-grey {
        min-width: 0.875rem;
        height: 0.875rem;
        background-color: variables.$input-border;
      }

      &--green {
        min-width: 0.875rem;
        height: 0.875rem;
        background-color: variables.$hauc-green;
      }
    }

    &__label {
      margin-left: 1rem;
      font-size: 1.3125rem;
      font-weight: 400;
      width: 100%;
    }

    &__value {
      text-align: right;
      font-size: 1.3125rem;
      font-style: normal;
      font-weight: 400;
      margin-left: 1rem;
    }
  }
}
