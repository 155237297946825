@use "../../styles/variables";

.primary-button {
  color: variables.$hauc-green;
  border: 2px solid variables.$hauc-green;
  text-decoration: none;
  font-weight: bolder;
  padding: 14px;
  background-color: transparent;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;

  &:hover,
  &--active {
    color: white;
    background-color: variables.$hauc-green;
  }

  &--disabled {
    color: variables.$input-border;
    pointer-events: none;
    border-color: variables.$input-border;
  }
}
