@use "../../../styles/variables";

.toggleable-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;

  &__card {
    width: 100%;
    height: 100%;
  }

  &__doughnut {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  &__data-toggle {
    background-color: variables.$dark-grey;
    padding: 3rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: auto;

    &__radio-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;
      height: 100%;
      min-height: 300px;
    }
  }
}
