@use "../../styles/variables";

.login {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/login-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__sidebar {
    max-width: 31rem;
    height: 100%;
    background-color: variables.$dark-grey;
    z-index: 1;

    &__logo {
      width: 150px;
      height: 150px;
      padding: 80px 0;
      margin: auto;

      @media screen and (min-width: 1920px) {
        width: 200px;
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__form {
      max-width: 18rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;

      > * {
        margin-bottom: 1rem;
      }
    }

    &__bottom {
      max-width: 18rem;
      margin: auto;
      color: variables.$hauc-green;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 4rem;
    }

    &__mail-link {
      max-width: 18rem;
      margin: 0;
      color: variables.$hauc-green;
    }

    &__verify {
      width: 20rem;
      margin: auto;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__verify-link {
      max-width: 20rem;
      margin: 0;
      color: white;
    }
  }
}
