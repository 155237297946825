.input-row {
  display: grid;
  grid-template-columns: 240px 240px;
  align-items: center;
  margin-bottom: 24px;

  div {
    justify-self: flex-end;
  }
}
