@use "../../styles/variables";

.side-nav {
  background-color: variables.$dark-grey;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 15rem;
  min-width: 15rem;

  a,
  button {
    font-size: 0.75rem;
  }

  @media screen and (min-width: 1920px) {
    a,
    button {
      font-size: 1rem;
    }

    width: 20rem;
    min-width: 20rem;
  }

  &__logo {
    margin-top: 48px;
    text-align: center;

    img {
      width: 120px;
      height: 120px;
    }
  }

  &__main {
    flex: 1;
  }

  ul {
    padding: 24px;
    margin-top: 40px;
  }

  li {
    list-style-type: none;
    margin-bottom: 1rem;
  }
}
