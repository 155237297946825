@use "../../styles/variables";

.delete-button {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid variables.$hauc-green;
  margin-right: 12px;
  background-color: transparent;
  cursor: pointer;

  img {
    height: 1rem;
  }

  &--active {
    display: none;
  }

  &:hover {
    background-color: variables.$hauc-green;
    color: white;
    stroke: white;

    &--inactive {
      display: none;
    }

    &--active {
      display: block;
    }
  }

  &:disabled {
    color: variables.$input-border;
    pointer-events: none;
    border-color: variables.$input-border;
  }
}
