@use "../../styles/variables";

.field-group {
  h2 {
    font-size: 1rem;
    margin-bottom: 24px;
  }
  border: 1px solid variables.$input-border;
  padding: 1.5rem;
  margin-bottom: 40px;
}
