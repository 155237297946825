.sector-dashboard {
  overflow: auto;
  display: grid;
  grid-template-rows: 55% 45%;
  height: 100%;
  grid-row-gap: 1.5rem;
  max-height: 100%;

  &__row {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
    height: 100%;

    &__single {
      flex-grow: 1;
      height: 100%;
    }

    &__double {
      flex-grow: 2;
      height: 100%;
    }

    &__triple {
      width: 100%;
      height: 100%;
    }
  }
}
