@use "../../styles/variables";

.nav-button {
  display: flex;
  align-items: center;
  color: variables.$hauc-green;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
  background-color: transparent;
  font-size: 0.875rem;
  border: 0 none;
  text-align: left;

  .nav-button__icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid variables.$hauc-green;
    margin-right: 12px;
    flex-shrink: 0;

    img {
      height: 12px;
    }

    &--active {
      display: none;
    }
  }

  &--active {
    pointer-events: none;
  }

  &:hover,
  &--active {
    .nav-button__icon {
      background-color: variables.$hauc-green;
      color: white;
      stroke: white;

      &--inactive {
        display: none;
      }

      &--active {
        display: block;
      }
    }
  }
}
