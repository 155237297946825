@use "../../../styles/variables";

.number-input {
  position: relative;
  width: 100%;

  &__label {
    position: absolute;
    font-size: 1rem;
    line-height: 1;
    color: variables.$input-border;
    top: calc(50% - 0.5rem);
    left: 12px;
    transition: all 0.2s ease;
    pointer-events: none;

    &--filled {
      font-size: 0.75rem;
      top: 8px;
      left: 12px;
    }
  }

  &__input-container {
    width: 100%;
    display: flex;
    border: 1px solid variables.$input-border;
  }

  &__input-box {
    height: 3.125rem;
    padding: 12px 12px 0 12px;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    color: variables.$dark-grey;
    border: 0px;
  }

  &__percentage {
    padding-right: 12px;
    color: variables.$input-border;
    margin: 0;
    display: block;
    margin-top: auto;
    padding-bottom: 12px;
  }
}
