@use "../../styles/variables";

.card {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  background-color: variables.$white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
  max-height: inherit;
  box-sizing: border-box;
  overflow: auto;
  width: 100%;

  &__title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    color: variables.$dark-grey;
    font-size: 1.3125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    color: variables.$dark-grey;
    font-size: 1.3125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }
}
