@use "../../../styles/variables";

.stat-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: inherit;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    align-items: baseline;
    border-bottom: 2px solid variables.$hauc-green;
    padding-bottom: 0.5rem;

    &__label {
      color: variables.$dark-grey;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      width: 100%;
    }

    &__stat {
      color: variables.$dark-grey;
      display: flex;
      align-items: flex-end;

      &__value {
        font-size: 2.5rem;
        font-weight: 500;
      }

      &__units {
        color: variables.$dark-grey;
        font-size: 1.125rem;
        font-weight: 400;
        margin-left: 0.5rem;
      }
    }
  }
}
