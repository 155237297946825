@use "../../styles/variables";

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.875rem;
  &__input {
    appearance: none;
    border-radius: 50%;
    border: 2px solid variables.$hauc-green;
    height: 40px;
    width: 40px;
    flex-shrink: 0;
    display: inline-flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      flex-shrink: 0;
    }

    &:checked:before {
      background-color: variables.$hauc-green;
    }
  }
  &__label {
    color: variables.$hauc-green;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}
