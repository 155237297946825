@use "../../styles/variables";

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-end;

  &__link {
    text-transform: uppercase;
    color: variables.$hauc-green;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
