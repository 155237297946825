@use "../../styles/variables";

.page-container {
  width: 100%;
  height: 100%;
  display: flex;

  &__child-container {
    display: grid;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding: 60px;
    box-sizing: border-box;
    grid-template-areas:
      "header header"
      "nav nav"
      "content content";
    grid-template-columns: 1fr 250px;
    grid-template-rows: auto 1fr;
    grid-gap: 40px;

    &--no-nav {
      grid-template-areas:
        "header header"
        "content content";
    }

    @media screen and (min-width: 1920px) {
      grid-template-areas:
        "header header"
        "content nav";
      grid-gap: 40px;
    }

    &__heading {
      grid-area: header;
    }

    &__navigation {
      grid-area: nav;
    }

    &__content {
      grid-area: content;
    }
  }
}

.title-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
