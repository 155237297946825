@use "../../../styles/variables";

.date {
  position: relative;
  width: 100%;

  &__label {
    position: absolute;
    font-size: 0.75rem;
    line-height: 1;
    color: variables.$input-border;
    top: 8px;
    left: 12px;
    transition: all 0.2s ease;
    pointer-events: none;
  }

  &__horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    &--seperator {
      padding: 12px;
    }
  }

  &__input {
    height: 3.125rem;
    padding: 12px 12px 0 12px;
    box-sizing: border-box;
    border: 1px solid variables.$input-border;
    font-size: 1rem;
    color: variables.$dark-grey;
    cursor: pointer;
    width: 100%;
  }
}
