@use "../../styles/variables";

.results-page-layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: variables.$light-grey;

  &__top-bar {
    background-color: variables.$white;
    padding: 2.5rem;
    padding-left: 1.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }

  &__bottom-bar {
    display: flex;
    background-color: variables.$white;
    padding: 1rem;
    padding-right: 1.5rem;
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.25);
    justify-content: end;
    gap: 1rem;
  }

  &__content {
    overflow: auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
  }
}
