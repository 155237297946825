@use "../../styles/variables";

.informational-pane {
  max-width: 100%;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: solid variables.$hauc-green;
  color: variables.$hauc-green;
  font-weight: bolder;
  text-transform: uppercase;

  &__text {
    width: 100%;
    flex-grow: 4;
    padding: 16px;
  }

  &__icon {
    height: 100%;
    flex-grow: 1;
    padding: 16px;
  }
}

.accordion-content {
  padding: 16px;

  display: none;
}

.informational-pane.open .accordion-content {
  display: block;
  border: solid variables.$hauc-green;
  border-top: none;
}

.arrow {
  transform: rotate(90deg);
  color: variables.$dark-grey;
}

.arrow.open {
  transform: rotate(-90deg);
}
