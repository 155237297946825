@use "../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  height: 100%;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.metric {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  &__title {
    color: variables.$dark-grey;
    font-size: 1.3125rem;
    font-weight: 500;
    margin: 0;
  }

  &__value {
    color: variables.$dark-grey;
    font-size: 2.75rem;
    font-weight: 500;
    line-height: normal;
    text-align: right;
    width: 100%;
  }

  &__units {
    color: variables.$dark-grey;
    font-size: 1.125rem;
    font-weight: 400;
    margin-left: 0.5rem;
  }
}
